<template>
  <div class="detailInfo">
    <div class="itemTitle">基本信息</div>
    <el-row>
      <el-col :span="12">
        <p>
          <span class="title">姓名：</span><span>{{ detail.userName}}</span>
        </p>
        <p>
          <span class="title">身份证号：</span><span>{{ detail.cardId}}</span>
        </p>
        <p>
          <span class="title">性别：</span><span>{{ getContent(sexList, detail.sex, 'label', 'text') }}</span>
        </p>
        <p>
          <span class="title">户籍地址：</span><span>{{ detail.residenceAddress}}</span>
        </p>
        <p>
          <span
            class="title">婚姻状况：</span><span>{{ getContent(maritalList, detail.maritalStatus, 'label', 'text') }}</span>
        </p>
      </el-col>
      <el-col :span="12">
        <p>
          <span class="title">联系电话：</span><span>{{ detail.phone}}</span>
        </p>
        <p>
          <span class="title">出生日期：</span><span>{{ detail.birthday}}</span>
        </p>
        <p>
          <span class="title">所属区域：</span><span>{{ detail.streetName+detail.orgName}}</span>
        </p>
        <p>
          <span class="title">家庭地址：</span><span>{{ detail.homeAddress}}</span>
        </p>
        <p>
          <span
            class="title">城乡类别：</span><span>{{ getContent(occupancyCategoryList, detail.occupancyCategory, 'label', 'text') }}
          </span>
        </p>
      </el-col>
    </el-row>
    <div class="itemTitle">居家养老信息</div>
    <p>
      <span class="title">服务对象类别：</span><span>{{ detail.objectName}}</span>
    </p>
    <p>
      <span class="title">服务内容：</span><span>{{ formatServiceContent(detail.objectServiceContent) }}</span>
    </p>
    <p>
      <span
        class="title">生活自理能力：</span><span>{{ getContent(livingAbilityList, detail.livingAbility, 'label', 'text') }}</span>
    </p>
    <p class="enclosure">
      <span class="title">附件：</span>
      <span class="img-wrap">
        <img :src="item" alt="" v-for="(item,index) in detail.enclosureImages" :key="index" @click="seePicture(item)">
      </span>
    </p>
    <p>
      <span class="title">是否委托代理人：</span><span>{{ detail.clientStatus===0 ? '是':'否' }}</span>
    </p>
    <div class="itemTitle" v-if="detail.clientStatus===0">委托代理人信息</div>
    <el-row v-if="detail.clientStatus===0">
      <el-col :span="12">
        <p>
          <span class="title">姓名：</span><span>{{ detail.clientName}}</span>
        </p>
        <p>
          <span class="title">出生日期：</span><span>{{ detail.clientBirthday}}</span>
        </p>
        <p>
          <span class="title">与申请人关系</span><span>{{ detail.clientRelation}}</span>
        </p>
      </el-col>
      <el-col :span="12">
        <p>
          <span class="title">身份证号：</span><span>{{ detail.clientCardId}}</span>
        </p>
        <p>
          <span class="title">性别：</span><span>{{ getContent(sexList, detail.clientSex, 'label', 'text') }}</span>
        </p>
        <p>
          <span class="title">联系电话：</span><span>{{ detail.clientPhone}}</span>
        </p>
      </el-col>
    </el-row>
    <div class="itemTitle">审核信息</div>
    <p style="display: block">
      <el-table :data="tableData" border style="width: 100%"
        :header-cell-style="{ background: 'rgba(238, 238, 238, 1)', color: 'rgba(51, 51, 51, 1)' }">
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="type" label="操作类型">
          <template slot-scope="scope">
            <span>{{getContent(applyList, scope.row.type, 'label', 'text')}}申请</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" />
        <el-table-column prop="handTime" label="操作时间" />
        <el-table-column prop="name" label="操作人" />
        <el-table-column prop="remarks" label="备注" />
      </el-table>
    </p>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { peopleDetail } from '@/api/pensionService'
import { getContent } from '@/utils/index'
import { AUDITSTATE, SERVICECONTENT } from '@/data/dataDictionary'
export default {
  // 居家养老服务--人员列表--查看详情
  name: 'DetailInfo',
  props: {
    id: Number
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      detail: {},
      tableData: [],
      contentList: [
        { label: '助老服务', value: 1 },
        { label: '信息服务', value: 2 }
      ],
      occupancyCategoryList: [
        { label: '城镇', value: 0 },
        { label: '农村', value: 1 }
      ],
      maritalList: [
        { label: '已婚', value: 1 },
        { label: '未婚', value: 2 },
        { label: '离异', value: 3 },
        { label: '丧偶', value: 4 }
      ],
      livingAbilityList: [
        { label: '自理', value: 1 },
        { label: '半失能', value: 2 },
        { label: '全失能', value: 3 }
      ],
      relationList: [
        { label: '父子(女)', value: 1 },
        { label: '母子(女)', value: 2 },
        { label: '夫妻', value: 3 },
        { label: '兄弟姐妹', value: 4 },
        { label: '祖孙', value: 5 }
      ],
      sexList: [
        { label: '女', value: 0 },
        { label: '男', value: 1 }
      ],
      applyList: [
        { label: '新增', value: 1 },
        { label: '变更', value: 2 },
        { label: '停发', value: 3 }
      ]
    }
  },
  mounted() {
    this.peopleDetail()
  },
  methods: {
    getContent,
    seePicture(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    formatServiceContent(vals) {
      if (!vals) {
        return false
      }
      let arr = vals.split(',')
      let newArr = arr.map((element) => {
        let val = SERVICECONTENT.find(
          (item) => item.value === Number(element)
        ).label
        return val
      })
      return newArr.join()
    },
    async peopleDetail() {
      const res = await peopleDetail({}, this.id)
      this.detail = res.data
      this.detail.enclosureImages = this.detail.enclosureImages.split(',')
      this.tableData = res.data.auditList
    }
  }
}
</script>

<style lang="scss" scoped>
.detailInfo {
  padding-left: 20px;
  .itemTitle {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    color: #999999;
    padding: 0 60px;
  }
  .title {
    display: inline-block;
    width: 15%;
    color: #333333;
    min-width: 160px;
    // text-align: right;
  }
}
.enclosure {
  display: flex;
}
.img-wrap {
  img {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
}
</style>
